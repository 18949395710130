<template>
	<ul class="nav">
		<li class="nav-item">
			<a href="/" aria-current="page" class="nav-link" :class="[activePage === 'home' ? 'active' : '']" @click="goHome" title="إلى الصفحة الرئيسية">
				<span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">cottage</span> الصفحة الرئيسية 
			</a>
		</li>
		<li class="nav-item" title="إلى نظرة عامة على العروض">
			<a href="/angebote" class="nav-link" :class="[activePage === 'angebote' ? 'active' : '']">
				<span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">calendar_today</span> العروض 
			</a>
		</li>
		<li class="nav-item" title="إلى نظرة عامة على المواقع">
			<a href="/orte" class="nav-link" :class="[activePage === 'orte' ? 'active' : '']">
				<span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">place</span> المرافق 
			</a>
		</li>
		<li class="nav-item" title="عرض المزيد من عناصر القائمة">
			<a @click="goMore" class="nav-link" :class="[activePage === 'mehr' ? 'active' : '']">
				<span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">more_vert</span> المزيد 
			</a>
		</li>
	</ul>
</template>
<script>

	export default {
		name: 'MobileNav',
		computed: {
			route() {
				return this.$route.path;
			},
			activePage() {
				if (this.$route.path === '/') {
					return 'home';
				} else if (this.$route.path.includes('orte')) {
					return 'orte';
				} else if (this.$route.path.includes('angebote')) {
					return 'angebote';
				}
				return 'mehr';
			}
		},
		methods: {
			goHome(){
				if(this.route == "/"){
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
				else{
					this.$router.push('/')
				}
			},
			goMore(){
				if(this.route == "/mehr"){
					return this.$router.back();
				}
				else{
					this.$router.push('/mehr')
				}
			}
		}
	}

</script>
<style lang="scss" scoped="">
</style>
